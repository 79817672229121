<template>
  <v-radio-group v-bind="$attrs" v-on="$listeners" :value="selectOption">
    <v-radio
      v-for="option in options"
      :key="option.value"
      :label="option.text"
      :value="option.value"
      :disabled="option.disabled"
    ></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: "RadioGroup",
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [{ value: 0, text: "Option 1", disabled: false }]
    },
    selectOption: {
      type: Number,
      required: false,
      default: 0
    }
  }
};
</script>
